<template>
	<div class="q-a__wrapper">
		<a class="flex" href="../showVideo.html">
			<img src="../../assets/Tutorials-log.png" class="fixed_btn flex-right"/>
		</a>
		<van-tabs
				v-model:active="active"
				background="#f5f5f5"
				title-active-color="#FF445E"
				title-inactive-color="#433C3C"
				:line-height="2"
				sticky
				swipeable
		>
			<van-tab title="教程" title-class="title-class">
				<Tutorial/>
			</van-tab>
			<van-tab title="问题" title-class="title-class">
				<Question/>
			</van-tab>

		</van-tabs>
		<div class="p-footer h-end">
			<button class="btn auto gray"
			        @click="$router.push('/index')">关闭
			</button>
		</div>
	</div>

</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'questionAndAnswer',
	components: {
		Tutorial: defineAsyncComponent(() => import('../questionAndAnswer/tutorial')),
		Question: defineAsyncComponent(() => import('../questionAndAnswer/question'))
	},
	data() {
		return {
			active: 0
		}
	}
}
</script>

<style scoped lang="scss">

.q-a__wrapper {
	padding: 0 px(15) px(15);
	position: relative;
}

:deep(.van-tabs__wrap) {
	border-bottom: 1px solid #DDDDDD;
}

:deep(.title-class) {
	flex: unset;
	font-weight: bold;
	font-size: px(18);

	&:nth-of-type(2) {
		margin-left: px(40);
	}
}

.fixed_btn {
	position: fixed;
	right: px(20);
	width: px(40);
	bottom: 20vh;
	height: px(40);
	z-index: 1;
}
</style>
